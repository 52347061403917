import React from "react";
import ApolloProvider from "./ApolloProvider";
import './App.scss'
import { BrowserRouter as Router} from "react-router-dom";
import AppRoutes from "./AppRoutes";

function App() {
  return (
    <ApolloProvider>
      <Router>
        <AppRoutes />
      </Router>
    </ApolloProvider>
  );
}

export default App;
