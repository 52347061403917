import React from "react";
import { Link } from "react-router-dom";
import warning from "../assets/warning.gif"

const ErrorComponent = (props) => {
	const heading = props?.heading
	const description = props?.description
	const link = props?.link
	const linkText = props?.linkText
	const className = props?.className

	return (
    <div className={`container-xl mt-30 ${className}`}>
			<div className="error-div">
				<img src={warning} alt="Error" className="mb-3" width={100} />
				<h5>{heading}</h5>
				<div className="fw-bold text-gray-700 mt-3">{description}</div>
				<Link to={link} className="btn btn-primary mt-3">{linkText}</Link>
			</div>
    </div>
	);
}

export default ErrorComponent;