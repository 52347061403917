import decode from "jwt-decode";
class AuthService {
    getUserProfile(token) {
        return decode(token);
    }

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken();
        return !!token && !this.isTokenExpired(token);
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) {
                return true;
            } else {
                return false;
            }
        } catch (err) {
            return false;
        }
    }

    getToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem("token");
    }

    login(token) {
        // Saves user token to localStorage
        localStorage.setItem("token", token);

        const search = window.location.href;
        let queryParams = new URL(search);
        const plan = queryParams.searchParams.get('plan');

        plan ?
            parseInt(plan) === 1 ?
                window.location.assign(`https://buy.stripe.com/bIY7sDcxV9uu8IUeUX?client_reference_id=${decode(token).sub}`)
            : parseInt(plan) === 2 ?
                window.location.assign(`https://buy.stripe.com/4gw28jfK70XYaR2148?client_reference_id=${decode(token).sub}`)
            : parseInt(plan) === 3 ?
                window.location.assign(`https://buy.stripe.com/7sIdR17dB5eegbm005?client_reference_id=${decode(token).sub}`)
            : window.location.assign(`/`)
        :
        window.location.assign(`/`)
    }

    logout() {
        // Clear user token and profile data from localStorage
        localStorage.removeItem("token");
        // this will reload the page and reset the state of the application
        window.location.assign(`/`)
    }
}

export default new AuthService();
