import React, { Suspense, lazy, useEffect } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import Auth from "./utils/auth";
import smartlookClient from 'smartlook-client'
import Tap from "@tapfiliate/tapfiliate-js";

import './App.scss'
import { FETCH_USER } from "./utils/queries";
import { useQuery } from "@apollo/client";
import LoadingComponent from "./components/LoadingComponent";
import ErrorComponent from "./components/ErrorComponent";

// const Home = lazy(() => import("./pages/Home"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Textbook = lazy(() => import("./pages/Textbook"));
const Quiz = lazy(() => import("./pages/Quiz/Quiz"));
const QuizResult = lazy(() => import("./pages/Quiz/QuizResult"));
const Lesson = lazy(() => import("./pages/Lesson/Lesson"));
const Login = lazy(() => import("./pages/Login"));
const Signup = lazy(() => import("./pages/Signup"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const SetPassword = lazy(() => import("./pages/SetPassword"));
const NoMatch = lazy(() => import("./pages/NoMatch"));
const ChangePassword = lazy(() => import("./pages/Settings/ChangePassword"));
const EditProfile = lazy(() => import("./pages/Settings/EditProfile"));
const Subscription = lazy(() => import("./pages/Settings/Subscription"));
const Profile = lazy(() => import("./pages/Settings/Profile"));
const Header = lazy(() => import("./components/Header"));
const AllMocks = lazy(() => import("./pages/Mock/AllMocks"));
const Mock = lazy(() => import("./pages/Mock/Mock"));
const MockResult = lazy(() => import("./pages/Mock/MockResult"));
const MockSectionsResult = lazy(() => import("./pages/Mock/MockSectionsResult"));
const MockReview = lazy(() => import("./pages/Mock/MockReview"));
const MockEssay = lazy(() => import("./pages/Mock/MockEssay"));
const MockSection = lazy(() => import("./pages/Mock/MockSection"));
const EndOfTest = lazy(() => import("./pages/Mock/EndOfTest"));
const Pricing = lazy(() => import("./pages/Pricing/Pricing"));
const Thankyou = lazy(() => import("./pages/Thankyou"));

const AllPracticeSets = lazy(() => import("./pages/PracticeSet/AllPracticeSets"));
const PracticeSet = lazy(() => import("./pages/PracticeSet/PracticeSet"));
const PracticeSetResult = lazy(() => import("./pages/PracticeSet/PracticeSetResult"));
const PracticeSetReview = lazy(() => import("./pages/PracticeSet/PracticeSetReview"));
const EndOfPracticeSet = lazy(() => import("./pages/PracticeSet/EndOfTest"));

// const Footer = lazy(() => import("./components/Footer"));

function AppRoutes() {
  Tap.init('38664-6a33c3');
  smartlookClient.init('077f5e27c7ddbf33a98628822ec9df51de1749b1', { region: 'eu' })


  const location = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  }
  useEffect(() => {
    onTop()
  }, [location]);
  const { loading: loadingUser, error: errorUser, data: dataUser } = useQuery(FETCH_USER, {
    fetchPolicy: 'network-only',
    skip: !Auth.loggedIn()
  });
  
  if (loadingUser) return (
		<LoadingComponent height={100} />
	);

	if (errorUser) return (
		<ErrorComponent heading="Oops! Page Not Found" description="Failed to load page, this maybe because of network error or page has been transferred to a new link. Reload the page or contact customer support for further assistance" link="/dashboard" linkText="Back to Dashboard" />
	);

	const user = dataUser?.fetchUser;

  smartlookClient.identify(`${user ? user?.id : null}`, {
    "name": `${user ? user?.fullName : null}`,
    "email": `${user ? user?.email : null}`,
  })

  function loggedInRoutes() {
    if (Auth.loggedIn()) {
      if (user?.subscriptionStatus === 'subscribed'){
        return(
          <>
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/" element={<Navigate to="/dashboard"/>} />
            <Route exact path="/login" element={<Navigate to="/dashboard"/>} />
            <Route exact path="/signup" element={<Navigate to="/dashboard"/>} />
            <Route exact path="/textbook" element={<Textbook />} />
            <Route exact path="/lesson/:id" element={<Lesson />} />
            <Route exact path="/quiz/:id" element={<Quiz />} />
            <Route exact path="/quiz-result/:id" element={<QuizResult />} />
            <Route exact path="/mocks" element={<AllMocks />} />
            <Route exact path="/mock/:id" element={<Mock />} />
            <Route exact path="/mock/:id/essays" element={<MockEssay />} />
            <Route exact path="/mock/:id/:section" element={<MockSection />} />
            <Route exact path="/mock/:id/end_of_test" element={<EndOfTest />} />
            <Route exact path="/mock-result/:id" element={<MockResult />} />
            <Route exact path="/mock-sections-result/:id" element={<MockSectionsResult />} />
            <Route exact path="/mock-review/:id/:section" element={<MockReview />} />

            <Route exact path="/practice-sets" element={<AllPracticeSets />} />
            <Route exact path="/practice-set/:id/:duration" element={<PracticeSet />} />
            <Route exact path="/practice-set/:id/end_of_test" element={<EndOfPracticeSet />} />
            <Route exact path="/practice-set-result/:id" element={<PracticeSetResult />} />
            <Route exact path="/practice-set-review/:id" element={<PracticeSetReview />} />

            <Route exact path="/pricing" element={<Pricing />} />
            <Route exact path="/not-found" element={<NoMatch />} />
            <Route exact path="/change-password" element={<ChangePassword />} />
            <Route exact path="/subscription" element={<Subscription />} />
            <Route exact path="/edit-profile" element={<EditProfile />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/thankyou" element={<Thankyou />} />
            
            <Route path="*" element={<Navigate to="/not-found"/>} />
          </>
        )
      }
      else{
        return (
          <>
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/" element={<Navigate to="/dashboard"/>} />
            <Route exact path="/login" element={<Navigate to="/dashboard"/>} />
            <Route exact path="/signup" element={<Navigate to="/dashboard"/>} />
            <Route exact path="/textbook" element={<Textbook />} />
            <Route exact path="/lesson/:id" element={<Lesson />} />
            <Route exact path="/quiz/:id" element={<Quiz />} />
            <Route exact path="/quiz-result/:id" element={<QuizResult />} />
            <Route exact path="/pricing" element={<Pricing />} />
            <Route exact path="/not-found" element={<NoMatch />} />
            <Route exact path="/change-password" element={<ChangePassword />} />
            <Route exact path="/subscription" element={<Subscription />} />
            <Route exact path="/edit-profile" element={<EditProfile />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/mocks" element={<AllMocks />} />
            <Route exact path="/practice-sets" element={<AllPracticeSets />} />
            <Route path="*" element={<Navigate to="/not-found"/>} />
            <Route exact path="/thankyou" element={<Thankyou />} />
          </>
        )
      }
    }
    else{
      return(
        <>
          <Route exact path="/" element={<Login />} />
          <Route
            exact
            path="/login"
            element={<Login />}
          />
          <Route
            exact
            path="/forgot-password"
            element={<ForgotPassword />}
          />
          <Route
            exact
            path="/set-password"
            element={<SetPassword />}
          />
          <Route
            exact
            path="/signup"
            element={<Signup />}
          />
          <Route
            exact
            path="/pricing"
            element={<Pricing />}
          />
          <Route exact path="/thankyou" element={<Thankyou />} />
          <Route path="*" element={<Navigate to="/login"/>} />
        </>
      )
    }
  }
  return (
    <Suspense fallback={
      <LoadingComponent height={100} />
    }>
      <Header />
      <main className="flex-shrink-0">
        <Routes>
          {loggedInRoutes()}
        </Routes>
      </main>
      {/* <Footer /> */}
    </Suspense>
  );
}

export default AppRoutes;
