import { gql } from "@apollo/client";

export const FETCH_USER = gql`	
query fetchUser {
  fetchUser {
    fullName
    email
    imageUrl
    id
    subscriptionStatus
    subscriptionExpiry
  }
}
`;

export const FIRST_LESSON = gql`
  query textbook{
    textbook {
      firstLesson{
        position
        id
        name
        description
        isCompleted
        isBookmarked
        nextLessonId
        quizId
        trialAvailability
      }
    }
  }
`;

export const ALL_CHAPTERS = gql`
  query textbook{
    textbook {
      chapters {
        id
        name
        position
        trialAvailability
        quiz {
          id
          questionsCount
          recordCompleted
        }
        lessons{
          id
          name
          position
          isCompleted
          isBookmarked
          trialAvailability
          quiz {
            id
            questionsCount
            recordCompleted
          }
        }
      }
    }
  }
`;

export const FETCH_LESSON = gql`
  query lessonShow ($id: ID!) {
    lessonShow (id: $id) {
      description
      id
      name
      position
      nextLessonId
      quizId
      isCompleted
      isBookmarked
      trialAvailability
    }
  }
`;

export const FETCH_LESSON_FOR_TEXTBOOK = gql`
  query lessonShow ($id: ID!) {
    lessonShow (id: $id) {
      chapterId
      trialAvailability
    }
  }
`;

export const FETCH_QUIZ_FOR_TEXTBOOK = gql`
  query fetchQuiz ($id: ID!) {
    fetchQuiz (id: $id) {
      chapterId
    }
  }
`;

export const FETCH_QUIZ_QUESTIONS_COUNT = gql`
  query fetchQuiz ($id: ID!) {
    fetchQuiz (id: $id) {
      questionsCount
    }
  }
`;

export const FETCH_QUIZ_RESULT = gql`
  query fetchQuizResult ($id: ID!) {
    fetchQuizResult (id: $id) {
      attempts{
        id
        isCorrect
        questionTitle
      }
      quizId
      nextLessonId
    }
  }
`;

export const FETCH_QUIZ = gql`
  query fetchQuiz ($id: ID!, $questionNumber: Int) {
    fetchQuiz (id: $id, questionNumber: $questionNumber) {
      id
      recordCompleted
      ownerId
      ownerType
      quizQuestions {
        quizId
        id
        position
        questionId
      }
      questionsCount
      question(questionNumber: $questionNumber){
        additionalText
        fullText
        id
        qsType
        textExplanation
        attempt(id: $id){
          isCorrect
          answers{
            content
            denominator
            index
            numerator
            options
          }
        }
        answers {
          ansType
          denominator
          id
          multipleOptions
          numerator
          numericAns
          position
          questionId
          options {
            answerId
            content
            id
            isCorrect
          }
        }
      }
    }
  }
`;

export const FETCH_MOCKS = gql`
  query fetchMocks {
    fetchMocks {
      id
      name
      position
      mockAttempted
    }
  }
`;

export const FETCH_MOCK_ESSAYS = gql`
  query fetchMock ($id: ID!) {
    fetchMock (id: $id) {
      id
      position
      mockAttempted
      essays{
        id
        essayType
        text
        attempted
      }
      mockSections{
        id
        attempted
      }
    }
  }
`;

export const FETCH_MOCK_ATTEMPTED = gql`
  query fetchMock ($id: ID!) {
    fetchMock (id: $id) {
      mockAttempted
    }
  }
`;


export const FETCH_MOCK_SECTION = gql`
  query fetchMockSection ($id: ID!, $type: String!, $questionNumber: Int) {
    fetchMockSection (id: $id, type: $type, questionNumber: $questionNumber) {
      id
      name
      questionsCount
      mockId
      timer
      mockQuestionId(questionNumber: $questionNumber)
      isBookmarked(questionNumber: $questionNumber)
      attempted
      attempt{
        isCorrect
        answers{
          content
          denominator
          index
          numerator
          options
        }
      }
      question(questionNumber: $questionNumber){
        fullText
        id
        qsType
        textExplanation
        additionalText
        answers {
          ansType
          denominator
          id
          multipleOptions
          numerator
          numericAns
          position
          questionId
          options {
            answerId
            content
            id
            isCorrect
          }
        }
      }
    }
  }
`;

export const FETCH_MOCK_SECTION_ID = gql`
  query fetchMockSection ($id: ID!, $type: String!) {
    fetchMockSection (id: $id, type: $type) {
      id
      attempted
    }
  }
`;

export const FETCH_MOCK_RESULT = gql`
  query fetchMockResult ($id: ID!) {
    fetchMockResult (id: $id) {
      essay1
      essay1Text
      essay1Feedback
      quantSections
      section1
      section2
      section3
      section4
      verbalSections
      quantSections
    }
  }
`;

export const FETCH_MOCK_SECTIONS_RESULT = gql`
  query fetchMockSectionsResult ($id: ID!) {
    fetchMockSectionsResult (id: $id) {
      name
      attempts{
        question{
          title
          difficultyLevel
        }
        attempt{
          isCorrect
        }
        
      }
    }
  }
`;

export const FETCH_SECTION_REVIEW = gql`
  query fetchSectionReview ($id: ID!) {
    fetchSectionReview (id: $id) {
      isBookmarked
      questionStatus (id: $id)
      position
    }
  }
`;

export const FETCH_DASHBOARD_WIDGETS = gql`
  query fetchMainWidgets {
    fetchMainWidgets {
      lastMockScore{
        essay1
        id
        quantSections
        updatedAt
        verbalSections
        mockName
      }
      mocksCompleted
      practiceSetsCompleted
      totalPracticeSets
      totalMocks
      chaptersCompleted
      lessonsCompleted
      totalChapters
      quantQuestionsAttempted
      verbalQuestionsAttempted
    }
  }
`;


// Practice Sets

export const FETCH_PRACTICE_SETS = gql`
  query fetchPracticeSets {
    allPracticeSets {
      id
      name
      attemptStarted
      position
      subjectType
      practiceSetAttempted
      duration
    }
  }
`;

export const FETCH_PRACTICE_SET = gql`
  query fetchPracticeSet ($id: ID!) {
    fetchPracticeSet (id: $id) {
      id
      position
      name
      practiceSetAttempted
      subjectType
    }
  }
`;

export const FETCH_PRACTICE_SESSION = gql`
  query fetchPracticeSession ($practiceSetId: ID!, $questionNumber: Int) {
    fetchPracticeSession (practiceSetId: $practiceSetId, questionNumber: $questionNumber) {
      id
      questionsCount
      practiceSetId
      practiceSetQuestionId(questionNumber: $questionNumber)
      isBookmarked(questionNumber: $questionNumber)
      practiceSetAttempted
      practiceSet{
        subjectType
        name
        duration
      }
      practiceAttempt{
        isCorrect
        answers{
          content
          denominator
          index
          numerator
          options
        }
      }
      question(questionNumber: $questionNumber){
        fullText
        id
        qsType
        textExplanation
        additionalText
        answers {
          ansType
          denominator
          id
          multipleOptions
          numerator
          numericAns
          position
          questionId
          options {
            answerId
            content
            id
            isCorrect
          }
        }
      }
    }
  }
`;

export const FETCH_PRACTICE_SESSION_ID = gql`
  query fetchPracticeSession ($practiceSetId: ID!) {
    fetchPracticeSession (practiceSetId: $practiceSetId) {
      id
      practiceSetAttempted
    }
  }
`;

export const FETCH_PRACTICE_SET_RESULT = gql`
  query fetchPracticeSetResult ($id: ID!) {
    fetchPracticeSetResult (id: $id) {
      name
      sessionScore
      totalQuestionsCount
      correctAttemptsCount
      attempts{
        question{
          title
          difficultyLevel
        }
        attempt{
          isCorrect
        }
        
      }
    }
  }
`;

export const FETCH_PRACTICE_SET_REVIEW = gql`
  query fetchPracticeSetReview ($practiceSetId: ID!) {
    fetchPracticeSetReview (practiceSetId: $practiceSetId) {
      isBookmarked
      questionStatus(practiceSetId: $practiceSetId)
      position
    }
  }
`;