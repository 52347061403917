import { ApolloClient, InMemoryCache, ApolloProvider as Provider, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';

const uploadLink = createUploadLink({ uri: 'https://stellargre.herokuapp.com/graphql', fetch: fetch });
// const httpLink = createHttpLink({ uri: 'https://stellargre.herokuapp.com/graphql' });
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
  };
});
const client = new ApolloClient({
  link: ApolloLink.from([authLink, uploadLink]),
  cache: new InMemoryCache()
});

export default function ApolloProvider(props){
    return <Provider client={client} {...props}/>
}